import * as Sentry from '@sentry/node';
import { TypographyStyle } from '@yamsafer/styleguide';
import WebAppShell from '@yamsafer/web-app-shell';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

Sentry.init({
	dsn: process.env.sentryDsn,
	enabled: process.env.NODE_ENV === 'production'
});

interface ModifiedAppProps extends AppProps {
	err: any;
}

const configs = {
	algolia: {
		appId: process.env.algoliaAppId,
		apiKey: process.env.algoliaApiKey
	}
};

const useUserAgent = (initialUserAgent: string) => {
	const [userAgent, setUserAgent] = useState(initialUserAgent);
	useEffect(() => {
		setUserAgent(window.navigator.userAgent);
	}, []);
	return userAgent;
};

function MyApp({ Component, pageProps, err }: ModifiedAppProps) {
	const { query } = useRouter();
	const userAgent = useUserAgent(pageProps.userAgent);
	const locale = pageProps.locale || query.locale;

	useEffect(() => {
		Modal.setAppElement('#__next');
	}, []);

	return (
		<WebAppShell
			context={{
				locale,
				device: 'phone'
			}}
			query={query}
			locale={locale}
			configs={configs}
			userAgent={userAgent}
			popStateHandler={() => console.log('hey')}
		>
			<TypographyStyle locale={locale} />
			<Component {...pageProps} err={err} />
		</WebAppShell>
	);
}

export default MyApp;
